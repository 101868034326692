export enum DownloadExportStatus {
  IN_PROGRESS = 'In Progress',
  SUCCESS = 'Success',
  FAILED = 'Failed',
}

export enum DownloadExportType {
  XLS = 'XLS',
  CSV = 'CSV',
  PDF = 'PDF',
}

export enum CreditRiskIntelligenceType {
  REPORTS = 'REPORTS',
  PORTFOLIOS = 'PORTFOLIOS',
  PORTFOLIO_REPORTS = 'PORTFOLIO_REPORTS',
  BATCHES = 'BATCHES',
}

export enum ReportTypeEnum {
  SINGLE_MDI = 'SINGLE_MDI',
  BATCH_ADS = 'BATCH_ADS',
  BATCH_MDI = 'BATCH_MDI',
  SINGLE_LOAN = 'SINGLE_LOAN',
  BATCH_LOAN = 'BATCH_LOAN',
}

export enum UploadTableNameEnum {
  UPLOAD_SINGLE_MDI = 'UPLOAD_SINGLE_MDI',
  UPLOAD_BATCH_ADS = 'UPLOAD_BATCH_ADS',
  UPLOAD_BATCH_MDI = 'UPLOAD_BATCH_MDI',
  UPLOAD_SINGLE_LOAN = 'UPLOAD_SINGLE_LOAN',
  UPLOAD_BATCH_LOAN = 'UPLOAD_BATCH_LOAN',
}

/**
 * All possible tables - display and upload
 */
export enum TableNameEnum {
  // DISPLAY
  SME_REPORTS = 'SME_REPORTS',
  BATCH_REPORTS = 'BATCH_REPORTS',
  BATCH_REPORT = 'BATCH_REPORT',
  PORTFOLIOS = 'PORTFOLIOS',
  PORTFOLIO_REPORTS = 'PORTFOLIO_REPORTS',
  PORTFOLIO_ID_REPORTS = 'PORTFOLIO_ID_REPORTS',
  COMPANY_LOANS = 'COMPANY_LOANS',
  PORTFOLIO_COMPANIES = 'PORTFOLIO_COMPANIES',
  WI_EXPORTS = 'WI_EXPORTS',
  COMPANY_REPORTS = 'COMPANY_REPORTS',
  // UPLOAD
  UPLOAD_SINGLE_MDI = 'UPLOAD_SINGLE_MDI',
  UPLOAD_ADDITIONAL_MDI = 'UPLOAD_ADDITIONAL_MDI',
  UPLOAD_BATCH_ADS = 'UPLOAD_BATCH_ADS',
  UPLOAD_BATCH_MDI = 'UPLOAD_BATCH_MDI',
}

// ------------------

// Enum for company types
export enum CompanyType {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

// Enum for management experience
export enum ManagementExperience {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

// Enum for AccountsType
export enum AccountsType {
  ALL_STATEMENTS = 0,
  RECENT_ACCOUNTS = 1,
  NO_LIMITED_FINANCIALS = 2,
  CONSOLIDATED = 3,
  LOCAL_REGISTRY = 4,
  UNCONSOLIDATED = 5,
  ANNUAL_REPORT = 6,
  NO_RECENT_FINANCIALS = 7,
}

// Enum for ProductType
export enum ProductType {
  SCREENING = 'SCREENING',
  DECISIONING = 'DECISIONING',
  MONITORING = 'MONITORING',
  ALERTING = 'ALERTING',
}
