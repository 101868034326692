/* eslint-disable security/detect-object-injection */
/* eslint-disable sonarjs/prefer-immediate-return */
import countryCodes from '../../lib/data/countryCodes.json';
import WfIsoCodes from '../../lib/data/iso_code_wf_api.json';
import type { BatchAutoUploadHeaders } from '../../types/batch-reports';
import type { CSVValidationHeaderProps } from '../../types/global';
import { LoanDataUploadHeaders } from '../../types/portfolio';
import { CsvReportUploadHeaders, CsvValueValidation } from '../../types/report';
import { dateIsValid, isValidDateString } from '../utils/date-helpers';
import { invalidCurrencyCodes } from './sme-calc.settings';

/**
 * @param strArr "['Active','Inactive']"
 * @returns string[] ['Active', 'Inactive']
 */
export const convertStringArrayToArrayOfStrings = (
  strArr: string
): { value: string[]; isValid: boolean } => {
  // the cell might start with either '"' or '['
  // check string has opening and closing brackets
  const startsWith = strArr.startsWith('[') || strArr.startsWith('"[');
  const endsWith = strArr.endsWith(']') || strArr.endsWith(']"');

  const isStringifiedArray = startsWith && endsWith;
  if (!isStringifiedArray) {
    return { value: [], isValid: false };
  }

  // remove brackets and any quotations from around the values
  // eg "['Active','Inactive']" => "Active,Inactive"
  const valuesFromString = strArr.replace(/"|'|\[|\]/g, '');

  // "Active,Inactive" => ['Active','Inactive']
  const splitValues = valuesFromString.split(',');

  return { value: splitValues, isValid: true };
};

const isNull = (string: string | number) =>
  typeof string === 'string' && string.toLowerCase() === 'null';

const validateCompanyType = (company_type: string) => {
  if (
    company_type?.toLowerCase() === 'large' ||
    company_type?.toLowerCase() === 'medium' ||
    company_type?.toLowerCase() === 'small' ||
    company_type?.toLowerCase() === 'ultracorp' ||
    company_type?.toLowerCase() === 'megacorp' ||
    company_type?.toLowerCase() === 'extralarge'
  ) {
    return true;
  }
  return false;
};

const isValidDateFormat = (x: string, row: number | undefined) => {
  if (!x) return false;
  if (isValidDateString(x)) return false;
  return `"${x}" is not a valid date format (YYYY-MM-DD) on row ${row}`;
};

const isValid2DecimalNumber = (x: string, row: number | undefined) => {
  if (!x) return false;
  if (x.split('.').length > 2)
    return `"${x}" is not a valid number on row ${row}`;
  if (x.split('.')?.[1]?.length > 2)
    return `"${x}" has more than two decimal places on row ${row}`;
  const num = Number(x);
  if (isNaN(num)) return `"${x}" is not a valid number row ${row}`;
  return false;
};

const validateManagementExperience = (management_experience: string) => {
  if (
    management_experience?.toLowerCase() === 'high' ||
    management_experience?.toLowerCase() === 'medium' ||
    management_experience?.toLowerCase() === 'low'
  ) {
    return true;
  }
  return false;
};

/**
 * Used to validate user-uploaded CSV report data.
 * `product_type` is excluded from the validation because it's added separately via
 * the radio buttons
 */
export const uploadReportCSVHeaders: {
  [K in Exclude<
    CsvReportUploadHeaders,
    'product_type'
  >]: CSVValidationHeaderProps;
} = {
  // company_name: {
  //   required: (x: string) => !x && `A value for "company_name" is required`,
  //   formatted: 'Company Name',
  //   optional_header: true,
  // },
  /**
   * *********************************
   * MAIN
   * *********************************
   */
  currency: {
    required: (x: string) => !x && `A value for "currency" is required`,
    formatted: 'Currency',
    validator: (x: string) =>
      countryCodes.find(country => country.currency_code === x) &&
      !invalidCurrencyCodes.includes(x)
        ? false
        : `"${x}" is not a valid currency code`,
  },
  iso_code: {
    required: (x: string) => !x && `A value for "iso_code" is required`,
    validator: (x: string) => {
      const codes = WfIsoCodes as { [key: string]: string };
      return codes?.[x] ? false : `"${x}" is not a valid ISO code`;
    },
    formatted: 'ISO Code',
  },
  company_id: {
    required: (x: string) => {
      if (x?.trim() === '' || isNull(x)) {
        return `A value for "company_id" is required`;
      }
      return false;
    },
    formatted: 'Company ID',
  },
  /**
   * *********************************
   * DETAILS
   * *********************************
   */
  // industry_sector_code: {
  //   optional_header: true,
  //   formatted: 'Industry Sector Code',
  // },
  details_industry_sector_code: {
    required: (x: string | number) => {
      // allow "" or "null" but no other string
      if (x.toString().trim() === '' || isNull(x)) {
        return false;
      }
      // must be a number not a string
      if (isNaN(Number(x))) {
        return `"details_industry_sector_code" should be a valid Industry Sector Code (null is allowed)`;
      }

      if (Number(x) < 10 || Number(x) > 38) {
        return `"details_industry_sector_code" must be valid Industry Sector Code [10-38]`;
      }
      return false;
    },
    formatted: 'Industry Sector Code',
  },
  details_name: {
    required: (x: string, i?: number) =>
      !x && `A value for "details_name" is required in row ${i}`,
    formatted: 'Company Name',
  },
  // nace_code: {
  //   optional_header: true,
  //   formatted: 'NACE Code',
  // },
  details_nace_code: {
    optional_header: true,
    formatted: 'NACE Code',
  },
  // company_type: {
  //   optional_header: true,
  //   formatted: 'Company Type',
  // },
  details_company_type: {
    optional_header: true,
    formatted: 'Company Type',
  },
  // not required but if there, validated as a website
  details_website: {
    required: false,
    formatted: 'Details website',
    validator: (x: string) => {
      const website = x?.trim();
      if (website) {
        return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
          website
        )
          ? false
          : `"${website}" is not a valid website address.`;
      }
      return false;
    },
  },
  /**
   * *********************************
   * FINANCIALS
   * *********************************
   */
  number_of_directors: {
    required: false,
    formatted: 'Number of directors',
  },
  number_of_subsidiaries: {
    required: false,
    formatted: 'Number of subsidiaries',
  },
  net_income: {
    required: false,
    formatted: 'Net Income',
  },
  number_of_employees: {
    required: false,
    formatted: 'Number of Employees',
  },
  cash_and_equivalents: {
    required: false,
    formatted: 'Cash',
  },
  total_assets: {
    optional_header: true,
    formatted: 'Total Assets',
  },
  ebit: {
    required: false,
    formatted: 'EBIT',
  },
  ebitda: {
    required: false,
    formatted: 'EBITDA',
  },
  tangible_fixed_assets: {
    required: false,
    formatted: 'Tangible Fixed Assets',
  },
  intangible_fixed_assets: {
    required: false,
    formatted: 'Intangible Fixed Assets',
  },
  interest_expenses: {
    required: false,
    formatted: 'Interest Expenses',
  },
  long_term_debt: {
    optional_header: true,
    formatted: 'Long Term Debt',
  },
  period: {
    required: (x: string) => !x && `A value for "period" is required`,
    validator: (x: string) =>
      !dateIsValid(x) && 'Period must be in a YYYY-MM-DD or YYYY format',
    formatted: 'Period',
  },
  retained_earnings: {
    required: false,
    formatted: 'Retained Earnings',
  },
  short_term_debt: {
    optional_header: true,
    formatted: 'Short Term Debt',
  },
  total_liabilities: {
    optional_header: true,
    formatted: 'Total Liabilities',
  },
  total_shareholder_equity: {
    required: false,
    formatted: 'Total Shareholder Equity',
  },
  turnover: {
    required: false,
    formatted: 'Turnover',
  },
  management_experience: {
    required: (x: string) =>
      !isNull(x) &&
      x?.trim()?.length > 0 &&
      !validateManagementExperience(x) &&
      `"management_experience" must be 'Low', 'Medium' or 'High', left blank or 'null'`,
    formatted: 'Management Experience',
  },
  creditors: {
    // required: (x: string) => !x && `A value for "creditors" is required`,
    optional_header: true,
    formatted: 'Creditors',
  },
  debtors: {
    // required: (x: string) => !x && `A value for "debtors" is required`,
    optional_header: true,
    formatted: 'Debtors',
  },
  fixed_assets: {
    optional_header: true,
    formatted: 'Fixed Assets',
  },
  inventory: {
    // required: (x: string) => !x && `A value for "inventory" is required`,
    optional_header: true,
    formatted: 'Inventory',
  },
  loans: {
    // required: (x: string) => !x && `A value for "loans" is required`,
    optional_header: true,
    formatted: 'Loans',
  },
  non_current_liabilities: {
    required: false,
    formatted: 'Non Current Liabilities',
  },
  other_non_current_liabilities: {
    optional_header: true,
    formatted: 'Other Non Current Liabilities',
  },
  current_assets: {
    required: false,
    formatted: 'Current assets',
  },
  current_liabilities: {
    required: false,
    formatted: 'Current liabilities',
  },
  company_age: {
    formatted: 'Company Age',
    required: false,
  },
  /**
   * *********************************
   * MACROECONOMICS
   * *********************************
   */
  macro_gdp_growth_annual: {
    formatted: 'GDP Growth Annual',
    required: false,
    optional_header: true,
  },
  macro_inflation: {
    formatted: 'Inflation',
    required: false,
    optional_header: true,
  },
  macro_unemployment: {
    formatted: 'Unemployment',
    required: false,
    optional_header: true,
  },
  macro_interest_rates: {
    formatted: 'Interest Rates',
    required: false,
    optional_header: true,
  },
};

export const batchAutoCsvHeaders: Record<
  BatchAutoUploadHeaders,
  CSVValidationHeaderProps
> = {
  iso_code: uploadReportCSVHeaders.iso_code,
  company_id: uploadReportCSVHeaders.company_id,
};

export const mapValidators = ([
  header,
  { required, validator, optional_header, formatted },
]: [string, CSVValidationHeaderProps]): CsvValueValidation => {
  let validators = [];

  if (required) {
    validators.push(required);
  }
  if (validator) {
    validators.push(validator);
  }

  return {
    header,
    formatted,
    ...(optional_header && {
      optional_header,
    }),
    ...((required || validator) && {
      validate: validators,
    }),
  };
};

export type LoanCsvHeaders = Record<
  LoanDataUploadHeaders,
  CSVValidationHeaderProps
>;

export const batchLoanCSVHeaders: LoanCsvHeaders = {
  company_id: {
    required: (x: string, row) =>
      x?.trim()?.length === 0 && `A "company_id" is missing on row ${row}.`,
    formatted: 'Company Id',
  },
  iso_code: {
    required: (x: string, row) =>
      !x && `An "iso_code" is missing on row ${row}.`,
    validator: (x: string, row) => {
      if (!x) return false;
      const codes = WfIsoCodes as { [key: string]: string };
      return codes?.[x] ? false : `"${x}" is not a valid ISO Code (row ${row})`;
    },
    formatted: 'ISO Code',
  },
  loan_id: {
    required: (x: string, row) =>
      x?.trim()?.length === 0 && `A "loan_id" is missing on row ${row}.`,
    formatted: 'Loan Id',
  },
  currency: {
    required: (x: string, row) =>
      !x && `A "currency" is missing on row ${row}.`,
    formatted: 'Currency',
    validator: (x: string, row) => {
      if (!x) return false;
      return countryCodes.find(country => country.currency_code === x) ||
        !invalidCurrencyCodes.includes(x)
        ? false
        : `"${x}" is not a valid currency code (row ${row})`;
    },
  },
  outstanding_balance: {
    required: (x, row) =>
      !x && `An "outstanding_balance" is missing on row ${row}.`,
    validator: isValid2DecimalNumber,
    formatted: 'Outstanding Balance',
  },
  commitment_limit: {
    required: (x, row) =>
      !x && `A "commitment_limit" is missing on row ${row}.`,
    validator: isValid2DecimalNumber,
    formatted: 'Commitment Limit',
  },
  security_type: {
    required: (x, row) => !x && `A "security_type" is missing on row ${row}.`,
    validator: (x, row) =>
      x?.trim()?.toUpperCase() === 'SECURED' ||
      x?.trim()?.toUpperCase() === 'UNSECURED'
        ? false
        : `"${x}" is not a valid security type [SECURED, UNSECURED] on row ${row}.`,
    formatted: 'Security Type',
  },
  security_value: {
    required: false, // x => !x && 'Security Value is required',
    validator: isValid2DecimalNumber,
    formatted: 'Security Value',
  },
  security_valuation_date: {
    required: false, // x => !x && 'Security Valuation Date is required',
    validator: isValidDateFormat,
    formatted: 'Security Valuation Date',
  },
  start_drawdown_date: {
    required: false, // x => !x && 'Start Date is required',
    validator: isValidDateFormat,
    formatted: 'Start Drawdown Date',
  },
  maturity_date: {
    required: false, // x => !x && 'Maturity Date is required',
    validator: isValidDateFormat,
    formatted: 'Maturity Date',
  },
  interest_rate: {
    required: false, // x => !x && 'Interest Rate Aer is required',
    validator: isValid2DecimalNumber,
    formatted: 'Interest Rate',
  },
  days_past_due: {
    required: false, // x => !x && 'Days Past Due is required',
    validator: (x, row) => {
      if (!x) return false;
      if (x.split('.').length > 1)
        return `"${x}" is should be an integer on row ${row}`;
      const num = Number(x);
      if (isNaN(num)) return `"${x}" is not a valid number on row ${row}`;
      return false;
    },
    formatted: 'Days Past Due',
  },
  credit_insurance: {
    required: false, // x => !x && 'Credit Insurance is required',
    // validator: (x, row) =>
    //   x?.trim()?.toUpperCase() === 'YES' || x?.trim()?.toUpperCase() === 'NO'
    //     ? false
    //     : `"${x}" is not a valid Credit Insurance value [YES, NO] on row ${row}.`,
    formatted: 'Credit Insurance',
  },
  balance_date: {
    required: false, // x => !x && 'As At Date is required',
    validator: isValidDateFormat,
    formatted: 'Balance Date',
  },
};

export const singleLoanCSVHeaders: LoanCsvHeaders = Object.entries(
  batchLoanCSVHeaders
).reduce((acc, [key, value]) => {
  if (key === 'company_id' || key === 'iso_code') {
    return {
      ...acc,
    };
  }

  return {
    ...acc,
    [key]: value,
  };
}, {} as LoanCsvHeaders);

export const manualUploadValidators: CsvValueValidation[] = Object.entries(
  uploadReportCSVHeaders
).map(mapValidators);

export const autoBatchUploadValidators: CsvValueValidation[] =
  Object.entries(batchAutoCsvHeaders).map(mapValidators);

export const singleLoanUploadValidators: CsvValueValidation[] =
  Object.entries(singleLoanCSVHeaders).map(mapValidators);

export const batchLoanUploadValidators: CsvValueValidation[] =
  Object.entries(batchLoanCSVHeaders).map(mapValidators);
